* {
  box-sizing: content-box;
}

html,
#root {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #eee;
}
